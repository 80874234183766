.og-image-crop-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: #333;
  z-index: 2000;
}

.og-image-crop {
  width: 100%;
  height: calc(100% - 290px);
  position: relative;
}

.og-image-crop-controls {
  width: 250px;
  height: 290px;
  display: flex;
  flex-direction: column;
}

.og-image-zoom-slider {
  margin-top: 10px;
}

.og-image-rotation-slider {
  margin-bottom: 12px;
}

.og-image-crop-cancel {
  margin: 17px !important;
}

.create-link-step-1-subdomain {
  display: inline;
  margin-bottom: 10px;
  text-align: center;
  padding: 5px;
}

.create-link-step-1-domain {
  line-height: 30px;
}

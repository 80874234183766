.custom-domain-toggle {
  margin-top: 10px !important;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 650px;
  font-size: 12px;
}

.custom-domain-toggle-seperator {
  width: 100%;
  max-width: 500px;
  margin-top: 25px;
  opacity: 0.1;
}

.custom-domain-toggle-highlighter {
  font-size: 10px;
  background-color: green;
  color: #FFFFFF;
  border-radius: 5px;
  padding: 5px 10px;
  text-decoration: none;
}

.link-preview-image {
  width: calc(100vw - 5px);
  max-width: 590px;
  height: 52.2033898306vw;
  max-height: 308px;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 20px 20px 0px 0px;  
  flex-grow: 0;
  flex-shrink: 0;
}

.link-preview-body {
  width: calc(100vw - 5px);
  max-width: 590px;
  border: 1px solid rgba(0,0,0,0.2);
  border-top: none;
  border-radius: 0px 0px 20px 20px;  
  flex-grow: 0;
  flex-shrink: 0;
  padding: 15px;
}

.link-preview-font-domain {
  font-size: 12px;
}

.link-preview-font-title {
  font-size: 18px;
}

.link-preview-font-description {
  font-size: 14px;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
}

/* prevent pull-down-to-refresh of mobile chrome */
html,
body {
  overscroll-behavior-y: contain;
}

.is-full-size {
  width: 100%;
  height: 100%;
}

.is-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.is-centered-item {
  width: 300px;
}

@media only screen and (max-width: 300px) {
  .is-centered-item {
    width: 270px;
  }
}

.has-pointer-cursor {
  cursor: pointer;
}

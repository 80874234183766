.container-lowered {
  width: 100%;
  height: calc(100% - 154px);
}

.container-topped {
  width: 100%;
  height: calc(100% - 64px);
}

.has-padding-5 {
  padding: 0 5%;
}

.dotted-bordered-box {
  text-align: center;
  padding: 2px;
  border: 1px dashed black;
  margin-bottom: 15px;
}

.align-checkbox {
  position: relative;
  top: -2px;
}

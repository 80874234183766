.link-list-empty-screen {
  position: fixed;
  width: 100%;
  height: calc(100% - 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 120px;
  left: 0;
}

.link-form-step-3-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.link-form-step-3-submit {
  margin-left: 50% !important;
  transform: translateX(-50%);
}

.link-form-step-3-footer {
  position: fixed;
  bottom: 0;
  padding: 15px;
  box-shadow: none !important;
  width: 100%;
  max-width: 600px;
}

.link-form-step-3-back {
  width: 100%;
  display: flex;
  justify-content: center;
}

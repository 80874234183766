.domain-card-wrapper {
  position: relative;
  margin: 0 7px;
}

.domain-card-status-chip {
  position: absolute;
  top: -8px;
  left: calc(100% + 8px);
  z-index: 10;
  transform-origin: right;
  transform: translate(-100%);
}
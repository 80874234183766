.link-card-image {
  width: 100%;
  padding-top: 51%;
  background-size: cover;
}

.link-card-wrapper {
  position: relative;
}

.link-card-tag-chip {
  position: absolute;
  top: -8px;
  left: calc(100% + 8px);
  z-index: 10;
  transform-origin: right;
  transform: translate(-100%);
}
@media only screen and (max-width: 1023px) {
  .show-desktop-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) {
  .hide-desktop-only {
    display: none !important;
  }
}